import React from 'react';
import {Link} from 'react-router-dom';
import { useSelector } from 'react-redux';

const CourseTitle = ({emne, onClick}) => {
    let isLogged = useSelector(state => state.isLogged);
    
    let deleteButton;

    if(isLogged) {
        deleteButton = (
            <button type="button" className="btn btn-danger btn-sm col-1" onClick={onClick}>X</button>
        )
    }

    return (
        <div className="container d-flex row m-2">
            <h4 key={emne} className="col-11">
                <Link to={`/courses/${emne}`} style={{ textDecoration: 'none', color: 'blue' }}>{emne}</Link>
            </h4>
            {deleteButton}
        </div>
    );
}
 
export default CourseTitle;