import React from 'react';
import '../App.css';
import {Link} from 'react-router-dom';
import logOut from '../utils/logOut';
import { useSelector, useDispatch } from 'react-redux';
import { logout, setCurrentUser } from '../actions';

function Nav() {
    let isLogged = useSelector(state => state.isLogged);
    const currentUser = useSelector(state => state.currentUser);
    const dispatch = useDispatch();

    const navStyle = {
        color: 'white'
    };

    function getWeekNumber(d) {
        // Copy date so don't modify original
        d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
        // Set to nearest Thursday: current date + 4 - current day number
        // Make Sunday's day number 7
        d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay()||7));
        // Get first day of year
        var yearStart = new Date(Date.UTC(d.getUTCFullYear(),0,1));
        // Calculate full weeks to nearest Thursday
        var weekNo = Math.ceil(( ( (d - yearStart) / 86400000) + 1)/7);
        // Return array of year and week number
        return weekNo;
    }
    
    var result = getWeekNumber(new Date());

    var logButton;
    if(!isLogged) {
        logButton = (
            <Link to='/admin'>
                <button className="btn btn-sm btn-light">Logg inn</button>
            </Link>
        )
    } else {
        logButton = (
            <button onClick={handleLogOut} className="btn btn-sm mx-1 btn-light">Logg ut</button>
        )
    }

    function handleLogOut() {
        console.log("Logget ut")
        logOut()
        dispatch(logout())
        dispatch(setCurrentUser(null))
    }

    return (
        <nav>
            <Link to='/' style={navStyle}>
                <h3 className="d-inline">Hjem</h3>
            </Link>
            
            <div id="admin">
            {logButton}
            {currentUser ? (
            <Link to='/admin'>
                <button className="btn btn-sm btn-light">Admin</button>
            </Link>)
            : ""}
            </div>
            
            <p>
                Uke {result}
            </p>
        </nav>
    )
}

export default Nav;