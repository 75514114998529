import React, {useEffect, useState} from 'react';
import '../App.css';
import getEmner from '../utils/getEmner';
import { Form, Button } from 'react-bootstrap';
import deleteEmne from '../utils/deleteEmne';
import writeCourse from '../utils/writeCourse';
import CourseTitle from './CourseTitle';


function Home() {
    const [emner, setEmner] = useState([]);
    const [newEmne, setNewEmne] = useState("");
    
    useEffect(() => {
        getEmner().then(emner => setEmner(emner))
    }, []);

    function handleSubmit(e) {
        e.preventDefault();
        setNewEmne(newEmne.toUpperCase().replace(/\s+/g, ''));
        if(newEmne !== "" && newEmne.length < 10 && !isNaN(newEmne.slice(-4)) && isNaN(newEmne.slice(1))) {
            writeCourse(newEmne.toUpperCase().replace(/\s+/g, ''));
            getEmner().then(emner => setEmner(emner));
            setNewEmne("");
        } else {
            alert("Ugyldig input. Skriv emnekode: Bokstaver + 4 sifre. Maks 9 karakterer.")
        }
    }

    function handleDelete(emne) {
        deleteEmne(emne);
        getEmner().then(emner => setEmner(emner));
    }

    return (
        <div>
            <h1 className="mb-3">Emneoversikt</h1>
            <Form onSubmit={handleSubmit}>
                <Form.Group controlId="ControlInput3">
                    <Form.Label>Fant ikke ditt emne?</Form.Label>
                    <Form.Control className="col-sm-3" value={newEmne} onChange={e => setNewEmne(e.target.value)} type="text" placeholder="Ny emnekode" />
                </Form.Group>
                <Button variant="success" type="submit">
                    Legg til nytt emne!
                </Button>
            <hr />
            </Form>
            {emner.map(emne => (
                <CourseTitle
                emne={emne}
                onClick={() => handleDelete(emne)}
                />))
            }
        </div>
    )
}

export default Home;
