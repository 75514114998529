import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Form, Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { modalclose } from '../actions';
import fire from '../fire';

function ResForm ({ match, getResources }) {
    const activeCourse = match.params.emne;
    const [tema, setTema] = useState("");
    const [desc, setDesc] = useState("");
    const [link, setLink] = useState("www.");
    const dispatch = useDispatch();

    function handleSubmit(e) {
        e.preventDefault();
        if(tema.length < 40 && desc.length < 300 && link.length < 300) {
		console.log({activeCourse, tema, desc, link});
        setRes();
        dispatch(modalclose())
        getResources()
        } else {
            alert("Ugyldig input. Maksgrense på tema: 40. Beskrivelse: 300. Link: 300.")
        }
    }

	function setRes() {
        const db = fire.firestore()
		db.collection("emner").doc(activeCourse).collection("resources").add({
			activeCourse,
			tema,
			desc,
			link,
			time: new Date()
		}).then(ref => {
			console.log('Added document with ID: ', ref.id);
		});
	}

    return (
        <Form onSubmit={handleSubmit}>
            <Form.Group controlId="ControlInput1">
                <Form.Label>Tema</Form.Label>
                <Form.Control value={tema} onChange={e => setTema(e.target.value)} type="text" placeholder="Hvilket tema?" />
            </Form.Group>
            <Form.Group controlId="ControlTextarea1">
                <Form.Label>Beskrivelse</Form.Label>
                <Form.Control value={desc} onChange={e => setDesc(e.target.value)} as="textarea" rows="3" />
            </Form.Group>
            <Form.Group controlId="ControlInput2">
                <Form.Label>Lenke</Form.Label>
                <Form.Control value={link} onChange={e => setLink(e.target.value)} type="text" placeholder="Lenke til ressurs" />
            </Form.Group>
            <Button variant="success" type="submit">
                Legg til ressurs
            </Button>
        </Form>
    );
}

export default withRouter(ResForm);
