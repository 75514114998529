import fire from '../fire';

const writeCourse = (newEmne) => {
    const db = fire.firestore()
    db.collection("emner").doc(newEmne).set({
        emne: newEmne
    }).then(newEmne => {
        console.log('Added document with ID: ', newEmne);
    });
}

export default writeCourse;
