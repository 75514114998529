import React from 'react';
import './App.css';
import Nav from './components/Nav';
import Home from './components/Home';
import Emne from './components/Emne';
import Login from './components/Login';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';

function App() {
  return (
    <div>
      <Router>
        <Nav />
        <div className="container mt-5">
          <Switch>
            <Route exact path="/" component={Home}/>
            <Route path="/courses/:emne" component={Emne}/>
            <Route path="/admin" component={Login}/>
          </Switch>
        </div>
      </Router>
    </div>
  );
}

export default App;
