import fire from '../fire';

const getRes = async(emnekode) => {
    const db = fire.firestore()
    let reslist = []
    console.log("Henter ressurser")

    await db.collection('emner').doc(emnekode).collection("resources").get()
    .then(snapshot => {
        snapshot.forEach(doc => {
            reslist.push(
                {   id: doc.id,
                    ...doc.data()})
        });
    })
    .catch(err => {
        console.log('Error getting documents', err);
    });

    return reslist;
}

export default getRes;
