import fire from '../fire';

const getEmner = async() => {
    const db = fire.firestore()
    let emner = []
    console.log("Henter emner")

    await db.collection('emner').get()
    .then(snapshot => {
        snapshot.forEach(doc => {
            emner.push(doc.id)
        });
    })
    .catch(err => {
        console.log('Error getting documents', err);
    });

    return emner;
}
export default getEmner;
