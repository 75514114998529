export const modalopen = () => {
    return {
        type: 'MODALOPEN'
    };
};

export const modalclose = () => {
    return {
        type: 'MODALCLOSE'
    };
};

export const logout = () => {
    return {
        type: 'LOGOUT'
    };
};

export const login = () => {
    return {
        type: 'LOGIN'
    };
};

export const setCurrentUser = (user) => {
    return {
        type: 'SETCURRENTUSER',
        payload: user
    };
};