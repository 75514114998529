import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import fire from '../fire';
import { useSelector, useDispatch } from 'react-redux';
import { login, setCurrentUser } from '../actions';
import { useHistory } from "react-router-dom";

const createUser = (email, password) => {
fire.auth().createUserWithEmailAndPassword(email, password).catch(function(error) {
    // Handle Errors here.
    var errorCode = error.code;
    var errorMessage = error.message;
    console.log(errorCode, errorMessage)
    alert(error)
});
};

const loginUser = (email, password) => {
fire.auth().signInWithEmailAndPassword(email, password).catch(function(error) {
    // Handle Errors here.
    var errorCode = error.code;
    var errorMessage = error.message;
    console.log(errorCode, errorMessage)
    alert(error)
  });
}

const Login = () => {
    let history = useHistory();

    const isLogged = useSelector(state => state.isLogged);
    const currentUser = useSelector(state => state.currentUser);
    const dispatch = useDispatch();
    
    const [epost, setEpost] = useState("");
    const [passord, setPassord] = useState("");

    function handleSignup(e) {
        e.preventDefault();
        createUser(epost, passord)
        fire.auth().onAuthStateChanged(function(user) {
            if (user) {
              console.log(user, "er innlogget")
              dispatch(login())
              dispatch(setCurrentUser(user.email))
              console.log(currentUser)
              return true;
            } else {
                console.log(user, "er ikke innlogget")
                return false;
            }
          });
    }

    function handleLogin(e) {
        e.preventDefault();
        loginUser(epost, passord)
        fire.auth().onAuthStateChanged(function(user) {
            if (user) {
              console.log(user, "er innlogget")
              dispatch(login())
              dispatch(setCurrentUser(user.email))
              console.log(currentUser)
              history.push("/")
              return true;
            } else {
                console.log(user, "er ikke innlogget")
                return false;
            }
          });
    }

    let isLoggedText;

    if(isLogged) {
        isLoggedText = (
            `Du er innlogget som ${currentUser}`
        ) 
    } else {
        isLoggedText = (
            "Du er ikke logget inn."
        )
    }

    function CheckCurrentUser() {
        fire.auth().onAuthStateChanged(function(user) {
            if (user) {
              console.log(user)
              return true;
            } else {
                console.log(user, "er ikke innlogget")
                return false;
            }
          });
    }

    return (
        <div className="container bg-light mt-5 col-8">
            <h6 onClick={CheckCurrentUser}>{isLoggedText}</h6>
            <Form>
                <Form.Group controlId="formBasicEmail">
                    <Form.Label>Epost</Form.Label>
                    <Form.Control value={epost} onChange={e => setEpost(e.target.value)} type="email" placeholder="Enter email" />
                    <Form.Text className="text-muted">
                    Vi deler ikke eposten med andre.
                    </Form.Text>
                </Form.Group>
                <Form.Group controlId="formBasicPassword">
                    <Form.Label>Passord</Form.Label>
                    <Form.Control value={passord} onChange={e => setPassord(e.target.value)} type="password" placeholder="Password" />
                </Form.Group>
                <div className="d-flex justify-content-between">
                <Button variant="primary" type="button" onClick={handleLogin}>
                    Logg inn
                </Button>
                {currentUser ? 
                <Button variant="secondary" type="button" onClick={handleSignup}>
                    Registrer
                </Button>
                : ""}
                </div>
            </Form>
        </div>
    );
}
 
export default Login;