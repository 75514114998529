import modalToggleReducer from './modalToggle';
import isLoggedReducer from './isLogged';
import currentUserReducer from './currentUser';
import {combineReducers} from 'redux';

const allReducers = combineReducers({
    modalToggle: modalToggleReducer,
    isLogged: isLoggedReducer,
    currentUser: currentUserReducer
});

export default allReducers;