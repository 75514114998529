import React, {useEffect, useState} from 'react';
import Ressurs from './Ressurs';
import getRes from '../utils/getRes';
import ResModal from './ResModal';

function Emne({ match }) {
	const emnenavn = match.params.emne;
	console.log(emnenavn)
	const [reslist, setReslist] = useState(
		[{emne: "", tema: "", desc: "", link: ""}, 
		{emne: "", tema: "", desc: "", link: ""}]
		);

    useEffect(() => {
		getRes(emnenavn).then(res => setReslist(res))
	}, [emnenavn]);

	function getResources() {
		getRes(emnenavn).then(res => setReslist(res))
	}

  	return (
		<div>
			<h1>{emnenavn}</h1>
			<hr className="mb-5"/>
			<ResModal getResources={getResources}/>
			{reslist.map(ressurs => (
				<Ressurs
				getResources={getResources}
				key={ressurs.id}
				resId={ressurs.id}
				title={ressurs.tema}
				desc={ressurs.desc}
				link={ressurs.link}
				/>
			))}
		</div>
	);
}

export default Emne;
