import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import '../App.css';
import deleteRes from '../utils/deleteRes';
import { useSelector } from 'react-redux';

function Ressurs ({ title, desc, link, resId, match, getResources }) {
    const activeCourse = match.params.emne
    const [currentId, setCurrentId] = useState(undefined);
    
    useEffect(() => {
        setCurrentId(resId);
    }, [resId]);

    const handleDelete = () => {
        deleteRes(activeCourse, currentId)
        getResources()
    }

    let isLogged = useSelector(state => state.isLogged);
    
    let deleteButton;

    if(isLogged) {
        deleteButton = (
            <button className="btn btn-sm btn-danger col-1" onClick={() => handleDelete(resId)}>X</button>
        )
    }
    
    return (
        <div className="my-4 row resource">
            <h5 className="d-inline col-11">{title}</h5>
            {deleteButton}
            <p className="col-11">{desc}</p>
            <p className="col-11"><a href={`//${link}`} rel="noopener noreferrer" target="_blank">{link}</a></p>
        </div>
    )
}

export default withRouter(Ressurs);