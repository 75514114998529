import fire from '../fire';

const logOut = () => {
    fire.auth().signOut().then(function() {
        console.log("Logget ut.")
      }).catch(function(error) {
        var errorCode = error.code;
        var errorMessage = error.message;
        console.log(errorCode, errorMessage)
      });
}

export default logOut;
