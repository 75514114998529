import firebase from 'firebase'
var config = {
    apiKey: "AIzaSyABEXMxxp4hBCZIqA67_jrpqGIa-hhDxSM",
    authDomain: "myprogress-e3349.firebaseapp.com",
    databaseURL: "https://myprogress-e3349.firebaseio.com",
    projectId: "myprogress-e3349",
    storageBucket: "myprogress-e3349.appspot.com",
    messagingSenderId: "208122283476",
    appId: "1:208122283476:web:c86e8de6309dd7661b4241"
};
var fire = firebase.initializeApp(config);
export default fire;